import { MouseEvent as ReactMouseEvent, TouchEvent as ReactTouchEvent } from 'react';

export const sinInversePercentage = (percent: number) => {
    return 0.5 * Math.sin(Math.PI * percent - Math.PI / 2) + 0.5;
}

export const getClientCoordinates = (event: ReactMouseEvent | ReactTouchEvent): { clientX: number, clientY: number } => {
    if (event.type.startsWith('mouse')) {
        // React.MouseEvent case
        const mouseEvent = event as ReactMouseEvent;
        return {
            clientX: mouseEvent.clientX,
            clientY: mouseEvent.clientY
        };
    } else if (event.type.startsWith('touch')) {
        // React.TouchEvent case
        const touchEvent = event as ReactTouchEvent;
        const touch = touchEvent.touches[0] || touchEvent.changedTouches[0];
        return {
            clientX: touch.clientX,
            clientY: touch.clientY
        };
    }
    // Fallback in case neither ReactMouseEvent nor ReactTouchEvent
    return {
        clientX: 0,
        clientY: 0
    };
}

export const getSquareSize = (ref: React.RefObject<HTMLDivElement> | null): number => {
    if (ref == null || ref.current == null)
        return -1;
    if (ref.current.children[0] instanceof HTMLElement)
        return ref.current.children[0].offsetWidth;
    return -1;
}