import './App.css';
import Home from "./pages/Home";
import Tutorial from "./pages/Tutorial";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

export default function App() {
  return (
    <div className="app">
      <Router>
        <header className="header">
          <Link to="/" id="logo-link" className="header-link">Chessicle</Link>
          <div>
            <Link to="/tutorial" id="tutorial-link" className="header-link">Chessicle Tutorial</Link>
          </div>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tutorial" element={<Tutorial />} />
        </Routes>
        {/* <footer>
          Footer
        </footer> */}
      </Router>
    </div >
  );
}